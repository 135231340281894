import * as React from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

const CACHE_KEY = 'cartItems'
const ProductsContext = createContext(null)
const ProductsDispatchContext = createContext(null)

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const INCREMENT_QUANTITY = 'INCREMENT_QUANITY'
export const DECREMENT_QUANTITY = 'DECREMENT_QUANITY'
export const CLEAR_ALL = 'CLEAR_ALL'
export const LOAD_CACHE = 'LOAD_CACHE'

const initial = []

export function useProducts() {
  return useContext(ProductsContext)
}

export function useProductsDispatch() {
  return useContext(ProductsDispatchContext)
}

function productsReducer(products, action) {
  switch (action.type) {
    case LOAD_CACHE: {
      return action.products
    }
    case ADD_PRODUCT: {
      return [
        ...products,
        {
          name: action.name,
          id: action.id,
          price: action.price,
          quantity: 1,
          total: action.price,
        },
      ]
    }
    case INCREMENT_QUANTITY: {
      return products.map((product) => {
        const quantity = product.quantity + 1

        if (product.id === action.id) {
          return {
            ...product,
            quantity,
            total: quantity * product.price,
          }
        }
        return product
      })
    }
    case DECREMENT_QUANTITY: {
      const { quantity } = products.find((product) => product.id === action.id)

      if (quantity === 1) {
        return products.filter((product) => product.id !== action.id)
      }
      return products.map((product) => {
        const newQuantity = product.quantity - 1
        if (product.id === action.id) {
          return {
            ...product,
            quantity: newQuantity,
            total: newQuantity * product.price,
          }
        }
        return product
      })
    }
    case REMOVE_PRODUCT: {
      return products.filter((product) => product.id !== action.id)
    }
    case CLEAR_ALL: {
      localStorage.setItem(CACHE_KEY, JSON.stringify(initial))
      return initial
    }
    default: {
      throw Error(`Unknown action: ${action.type}`)
    }
  }
}

// eslint-disable-next-line react/prop-types
export function ProductsProvider({ children }) {
  // eslint-disable-next-line no-use-before-define
  const [tasks, dispatch] = useReducer(productsReducer, initial)

  useEffect(() => {
    const products = JSON.parse(localStorage.getItem(CACHE_KEY))
    if (products) {
      dispatch({ type: LOAD_CACHE, products })
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(tasks))
  }, [tasks])

  return (
    <ProductsContext.Provider value={tasks}>
      <ProductsDispatchContext.Provider value={dispatch}>
        {children}
      </ProductsDispatchContext.Provider>
    </ProductsContext.Provider>
  )
}
